'use strict';
import timer from './modules/timer'

window.addEventListener('DOMContentLoaded', () => {

    if(document.querySelector('.timer-wrapper')){
        timer({
            wrapper: '.timer-wrapper'
        });
    }
});
